import { useWallet } from '@txnlab/use-wallet-react'
import dayjs from 'dayjs'
import Link from 'next/link'
import { useHydrated } from 'react-hydration-provider'
import { BiCopy, BiLinkExternal } from 'react-icons/bi'
import { HiPencil, HiOutlineChatAlt2, HiOutlineArrowCircleUp } from 'react-icons/hi'
import { useNfdConstraints } from '@/api/hooks/contracts/useNfdConstraints'
import confirm from '@/components/confirm'
import Avatar from '@/components/DetailView/Header/Avatar'
import Linkify from '@/components/Linkify'
import Button from '@/components/Button'
import RenewDialog from '@/components/mint/RenewDialog'
import { checkBalance } from '@/helpers/checkBalance'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { formatPrice } from '@/helpers/utilities'
import { useContractUpgrade } from '@/hooks/useContractUpgrade'
import useErrorToast from '@/hooks/useErrorToast'
import Banner from './Banner'
import Links from './Links'
import ProfileMenu from './ProfileMenu'
import PurchasedTip from './PurchasedTip'
import Donations from './Badges' // @todo: this is going away soon
import Badges from './MeritBadges' // @todo: rename this folder to Badges
import type { NfdRecord } from '@/types/api'

import { HeaderGrid, HeaderName, HeaderBio, HeaderAside, HeaderLinks } from './Header.css'

export type HeaderProps = {
  nfd: NfdRecord
  isOwner: boolean
  showTip: boolean
}

export default function Header({ nfd, isOwner, showTip }: HeaderProps) {
  const { activeAddress } = useWallet()
  const hydrated = useHydrated()
  const handleError = useErrorToast()

  const constraintsQuery = useNfdConstraints()
  const constraints = constraintsQuery.data
  const auctionDuration = constraints?.expiredAuctionDuration || 0
  const now = dayjs()
  const expirationDate = dayjs(nfd.timeExpires)
  const isExpired = expirationDate.isBefore(now)
  const auctionEndDate = expirationDate.add(auctionDuration, 'days')
  const isAuctionLive = auctionEndDate.isAfter(now)

  const hasVerifiedFields =
    !!nfd.properties?.verified &&
    Object.keys(nfd.properties?.verified).filter((key) => key !== 'caAlgo').length > 0

  const isContractLocked = nfd.properties?.internal?.contractLocked === '0' ? false : true

  const { isV2UpgradeRequired, isV3UpgradeRequired, handleUpgradeV3, priceOneYear } =
    useContractUpgrade(nfd)

  const handleV3Upgrade = async () => {
    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      if (isContractLocked) {
        throw new Error('Contract is locked. Please unlock it before upgrading.')
      }

      const balance = await checkBalance(activeAddress, priceOneYear)

      if (!balance.hasSufficientBalance) {
        throw new Error(
          `Insufficient available balance. A minimum available balance of ${formatPrice(
            balance.balanceRequired as number,
            false,
            { maximumFractionDigits: 6 }
          )} ALGO is required to complete this transaction.`
        )
      }
    } catch (error) {
      handleError(error)
    }

    const confirmed = await confirm({
      title: `Upgrade to Version 3`,
      confirmText: `Upgrade Contract`,
      confirmation: (
        <>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Version 3 smart contracts transition to a{' '}
            <strong className="text-gray-700 dark:text-gray-300">renewal model</strong>. To access
            new features and improvements, you must upgrade to the latest contract&nbsp;version.
          </p>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            The transaction to upgrade your NFD to version 3 includes a payment of{' '}
            <span className="font-semibold text-gray-900 dark:text-gray-100">
              {formatPrice(priceOneYear)} ALGO
            </span>{' '}
            for the first&nbsp;year.
          </p>
          <p className="mt-4 text-sm">
            <a
              href="https://nfdomains.medium.com/nfd-v3-building-for-tomorrow-e825bc4db3cc"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center gap-x-1.5 text-gray-900 font-semibold whitespace-nowrap hover:text-brand-500 underline dark:no-underline dark:text-brand-500 dark:hover:text-brand-600"
            >
              Learn more
              <BiLinkExternal className="h-4 w-4" aria-hidden="true" />
            </a>
          </p>
        </>
      )
    })

    if (confirmed) {
      handleUpgradeV3()
    }
  }

  const renderPrimaryAction = () => {
    if (!hydrated) {
      return null
    }

    if (isOwner) {
      if (isExpired) {
        return (
          <div>
            <RenewDialog nfd={nfd}>
              <Button
                variant="gradient"
                className="px-3 py-1.5 text-xs rounded-md md:px-4 md:py-2 xs:text-sm"
              >
                Renew Now
              </Button>
            </RenewDialog>
          </div>
        )
      }

      if (isV2UpgradeRequired || isV3UpgradeRequired) {
        return (
          <div className="flex gap-x-2">
            {isV2UpgradeRequired ? (
              <Link href={`/manage/${nfd.name}?view=contract`}>
                <Button
                  variant="gradient"
                  className="px-3 py-1.5 text-sm rounded-md md:px-4 md:py-2"
                >
                  <HiOutlineArrowCircleUp
                    className="hidden xs:inline -ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  Upgrade
                </Button>
              </Link>
            ) : (
              <Button
                variant="gradient"
                className="px-3 py-1.5 text-sm rounded-md md:px-4 md:py-2"
                onClick={handleV3Upgrade}
              >
                <HiOutlineArrowCircleUp
                  className="hidden xs:inline -ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Upgrade
              </Button>
            )}
            <Link href={`/manage/${nfd.name}`}>
              <Button variant="gradient" className="px-3 py-1.5 text-sm rounded-md md:px-4 md:py-2">
                <HiPencil className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                Edit
              </Button>
            </Link>
          </div>
        )
      }

      return (
        <div>
          <Link href={`/manage/${nfd.name}`}>
            <Button
              variant="gradient"
              className="px-3 py-1.5 text-xs rounded-md md:px-4 md:py-2 xs:text-sm"
            >
              <HiPencil className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
              Edit
            </Button>
          </Link>
        </div>
      )
    }

    if (activeAddress) {
      return (
        <div>
          <RenewDialog nfd={nfd}>
            <Button
              variant="gradient"
              className="px-3 py-1.5 gap-x-2 text-xs rounded-md md:px-4 md:py-2 xs:text-sm"
            >
              {isAuctionLive && (
                <svg
                  className="-ml-0.5 h-2 w-2 sm:h-2.5 sm:w-2.5 text-white animate-blink"
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx={4} cy={4} r={3} />
                </svg>
              )}
              Buy Now
            </Button>
          </RenewDialog>
        </div>
      )
    }

    return null
  }

  const renderSecondaryActions = () => {
    if (!hydrated || isExpired || isOwner) {
      return null
    }

    return (
      <Link href={`/offer/${nfd.name}`} className="hidden md:inline-block">
        <Button className="py-2 group whitespace-nowrap">
          <HiOutlineChatAlt2
            className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400"
            aria-hidden="true"
          />
          Make an offer
        </Button>
      </Link>
    )
  }

  return (
    <>
      <div>
        <Banner nfd={nfd} />
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 flex items-end space-x-1 sm:space-x-4 xl:-mt-20 xl:items-center">
            <Avatar key={nfd.name} nfd={nfd} alt="avatar" />
            <div className="flex-1 min-w-0 flex items-center justify-between sm:pb-2 xl:pb-0 space-x-2">
              <div className="xl:mt-16">
                <Donations nfd={nfd} />
              </div>
              <div className="flex items-center justify-stretch gap-x-3 xl:mt-16">
                {renderPrimaryAction()}
                {renderSecondaryActions()}

                <div className="px-1.5">
                  <ProfileMenu nfd={nfd} isOwner={isOwner} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HeaderGrid className="grid grid-cols-1 md:grid-cols-[1fr_16rem] mt-3 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <HeaderName className="min-w-0 mb-5">
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="text-2xl sm:text-3xl tracking-tight leading-normal font-bold text-gray-900 truncate rounded-sm pl-1 -ml-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 group dark:text-gray-100 dark:focus:ring-offset-gray-900"
              data-clipboard-text={nfd.name}
              onClick={copyToClipboard}
            >
              {nfd.name}
              <BiCopy className="hidden lg:inline ml-3 h-6 w-6 text-white group-hover:text-gray-400 group-focus:text-gray-400 dark:text-gray-900 dark:group-hover:text-gray-500 dark:group-focus:text-gray-500" />
            </button>
          </div>
          {nfd?.properties?.userDefined?.name && (
            <h3 className="text-sm text-gray-400 sm:text-base sm:mt-1.5 truncate dark:text-gray-500">
              {nfd.properties.userDefined.name}
            </h3>
          )}
        </HeaderName>
        <HeaderBio>
          {nfd.properties?.userDefined?.bio && (
            <div className="prose prose-gray text-sm sm:text-base md:max-w-full mb-5 dark:prose-invert">
              <Linkify showIcon decorate>
                <p className="whitespace-pre-line">{nfd.properties?.userDefined.bio}</p>
              </Linkify>
            </div>
          )}
        </HeaderBio>
        <HeaderLinks>
          {hasVerifiedFields && (
            <div className="mb-5">
              <Links nfd={nfd} />
            </div>
          )}
        </HeaderLinks>
        <HeaderAside>
          <Badges nfd={nfd} />
        </HeaderAside>
      </HeaderGrid>

      <PurchasedTip showTip={showTip} />
    </>
  )
}
